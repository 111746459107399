<template>
    <CModal :show.sync=" dt_signatory_modal"  color="primary"
        size="lg"
        :closeOnBackdrop="false">

        <template #header>
            <h5> <font-awesome-icon icon="address-book"/> {{selected_user.name + '\'s Document Template/s Signatory'}}</h5>
            <CButton 
                class="pull-right" 
                color="light" 
                shape="pill" 
                size="sm"
                @click=" dt_signatory_modal = false"
            >
                <font-awesome-icon icon="times"/>
            </CButton>
        </template>

        <template #body-wrapper>
            <CCardBody>
                <CDataTable
                    :items="active_templates"
                    :fields="document_templates_fields"
                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                    :items-per-page="10"
                    items-per-page-select
                    border
                    sorter
                    pagination
                    :loading="table_loading"
                    size="sm"
                >
                </CDataTable>
            </CCardBody>
        </template>
        <template #footer>
            <hr hide>
        </template>
    </CModal>
</template>


<script>
export default {
    name : 'DocumentTemplateSignatoryModal',
    data () {
        return {
            dt_signatory_modal : false,
            table_loading: true,
            templates: [],
            document_templates_fields: [
                {key: 'name', label: 'Template Name'},
                {key: 'purpose'},
                // {key: 'order'},
                // {key: 'requirements_count', label: 'Requirements'},                
                // {key: 'description', label: 'Description'},
            ],
            selected_user : {
                name : '',
                user_id : ''
            },
        }
    },
    computed: {
        active_templates: function (){
            return this.templates.filter((item) => item.is_active == 1).sort((a, b) => a.name.localeCompare(b.name));
        },
    },
    created () {
        this.$parent.$on('show_dt_signatory_modal', this.getDocumentTemplateSignatory);
    },
    methods : {
        getDocumentTemplateSignatory : function (user_id = null, name) {
          
            this.$Progress.start()   
            this.table_loading=true;
            this.selected_user.name = name;
            this.selected_user.user_id = this.paramEncoder(user_id);
             if(this.templates.length > 0){
                this.templates = [];
            }
            axios.get('/drs/document-template-signatory/' + this.selected_user.user_id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.table_loading = false;
                    for (let i = 0; i < response.data.data.length; i++) {
                        const element = response.data.data[i];
                        let _row = {
                            id: element.id,
                            name: element.document_templates.name,
                            purpose: element.purpose,
                            order: element.order,
                            requirements: element.document_signature_requirements,
                            requirements_count : element.document_signature_requirements.length,
                            is_active: parseInt(element.is_active)
                        }
                        if(parseInt(element.is_active) == 1) {
                            this.templates.push(_row);
                        }
                    }
                    this.table_loading = false;
                    this.$Progress.finish()            
                }
            })
        }
    }
}
</script>
